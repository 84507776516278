import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, PPV_ACTUALS_ASSIGN_GLOBAL_PORTFOLIO } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-assign-global-portfolio" }, [_c("div", { staticClass: "px-2" }, [_c("a-row", { staticClass: "header-container mb-3 px-4 pt-2 pb-3", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("span", { staticClass: "title" }, [_vm._v("Assign Procurement Category")])])], 1)], 1), _c("div", { staticClass: "card p-3 mx-5" }, [_c("div", { staticClass: "header-title" }, [_vm._v("Filter By")]), _c("a-row", { attrs: { "gutter": 24, "type": "flex", "jusify": "end", "align": "bottom" } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("select-filter", { attrs: { "placeholder": "Select Subclass", "reference": "ppv.common.ppv-sub-class", "queries": [["CountryID", _vm.countryId]], "label": "Subclass", "source": "subClass", "source-label": "subClassDesc", "value": _vm.selectedSubClass }, on: { "change": function($event) {
    return _vm.onSelectFilterChange($event, "SubClass");
  } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("select-filter", { attrs: { "placeholder": "Select Procurement Category", "reference": "ppv.common.ppv-global-portfolio", "label": "Procurement Category", "source": "gPortfolioId", "source-label": "gPortfolio", "value": _vm.selectedPortfolio }, on: { "change": function($event) {
    return _vm.onSelectFilterChange($event, "GPortFolioId");
  } } })], 1), _c("a-col", [_c("a-button", { attrs: { "icon": "close-circle" }, on: { "click": _vm.onClearFilterClick } }, [_vm._v("Clear Filter")])], 1)], 1)], 1), _c("list-composer", { attrs: { "has-active": false, "has-filter-inactive": false }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "customButton", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddNewClick } }, [_vm._v("Add New")])];
  }, proxy: true } : null, { key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Subclass Translations for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "default-active-first-option": "", "value": _vm.selectedCountry }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDel(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("text-field", { key: "SubClass", attrs: { "data-index": "subClass", "title": "Subclass", "sorter": true } }), _c("text-field", { key: "SubClassDesc", attrs: { "data-index": "subClassDesc", "title": "Sub Class Name", "sorter": true } }), _c("text-field", { key: "GPortFolio", attrs: { "data-index": "gPortFolio", "title": "Procurement Category", "sorter": true } })], 1)], 1);
};
var staticRenderFns$1 = [];
var AssignGlobalPortfolio_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "AssignGlobalPortfolio",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    selectedSubClass() {
      const statusFilter = this.crud.getQueryString("SubClass");
      return statusFilter || { value: void 0 };
    },
    selectedPortfolio() {
      const statusFilter = this.crud.getQueryString("GPortFolioId");
      return statusFilter || { value: void 0 };
    },
    countryId() {
      let value = this.crud.getQueryString("CountryID").value;
      return value;
    }
  },
  created() {
    this.getCountries();
    this.crud.setQueryString("CountryID", this.countryIdAU);
  },
  methods: {
    onDel(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Procurement Category Translation?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Procurement Category Translation?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/ppv/global-portfolio-trans`, {
          countryId: record.countryId,
          subClass: record.subClass,
          gPortFolioId: record.gPortFolioId,
          updateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    },
    onAddNewClick() {
      this.$router.push("/ppv-actuals/assign-global-portfolio/create");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "910a4610", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var AssignGlobalPortfolio = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-sub-class" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.common.ppv-global-portfolio" } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.global-portfolio-trans", "edit-route": "/ppv-actuals/assign-global-portfolio/:id", "page": _vm.page } }, [_c("assign-global-portfolio")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    AssignGlobalPortfolio
  },
  data() {
    return {
      page: PPV_ACTUALS_ASSIGN_GLOBAL_PORTFOLIO,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "assign-global-portfolio",
          title: "Assign Procurement Category",
          path: "/ppv-actuals/assign-global-portfolio"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
